import { toggleHamburger } from './toggleHamburger';
import { mvSlider } from './sliders';
import { accordion } from './accordion';
import { tabMenu } from './tabMenu';
import { changeHeaderColor } from './changeHeaderColor.js';

toggleHamburger('js-hamburger', 'js-spmenu', 1024);
accordion();
tabMenu();
changeHeaderColor();

// ajax

jQuery(function ($) {
  const load = $('.js-loadPostList');
  const dataNode = $('.js-addedPostData');
  const get_post_num = dataNode.data('added-num');
  let now_post_num = dataNode.data('visible-post');
  const post_type = dataNode.data('post-type');
  const all_count = dataNode.data('count');

  if (!load) return;
  if (!dataNode) return;
  if (!get_post_num) return;
  if (!post_type) return;
  if (!all_count) return;
  //admin_ajaxにadmin-ajax.phpの絶対パス指定（相対パスは失敗する）
  const host_url = location.protocol + '//' + location.host;
  // const admin_ajax = host_url + '/wp-admin/admin-ajax.php';
  const admin_ajax = host_url + '/control-panel/wp-admin/admin-ajax.php';

  $(document).on('click', '.js-moreCustomPostTypeBtn', function () {
    //読み込み中はボタン非表示
    $('.js-moreCustomPostTypeBtn').after(
      '<div class="p-archive-list__buttons js-loadingMessage"><p class="p-archive-list__loading">Now Loading...</p></div>'
    );
    $('.js-moreCustomPostTypeBtn').remove();

    //ajax処理。data{}のactionに指定した関数を実行、完了後はdoneに入る
    $.ajax({
      type: 'POST',
      url: admin_ajax,
      data: {
        action: 'get_more_custom_post_type_action', //functions.phpで設定する関数名
        now_post_num: now_post_num,
        get_post_num: get_post_num,
        post_type: post_type,
      },
    })
      .done(function (data) {
        $('.js-loadingMessage').remove();
        //my_ajax_action関数で取得したデータがdataに入る
        //.loadにデータを追加
        load.append(data);
        //表示件数を増やす
        now_post_num = now_post_num + get_post_num;
        //まだ全件表示されていない場合、ボタンを再度表示
        if (all_count > now_post_num) {
          load.after(
            '<div class="p-archive-list__buttons js-moreCustomPostTypeBtn"><button class="p-archive-list__button c-button " type="button">View More</button></div>'
          );
        }
      })
      .fail(function () {
        alert('エラーが発生しました');
      });
  });
});
